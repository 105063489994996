import React from "react";

const ClosedClassicRegister = () => {
    return (
        <div style={{ textAlign: "center" }}>
            <p>
                The Classic Register is currently on pause while we update and
                improve the programme and registration process. We’re looking to
                relaunch the programme at the beginning of Q2, existing members
                will still have full access to their benefits in the meantime.
            </p>
            <p>
                For more information, please contact
                classicregister@partner.pcms-porsche.co.uk
            </p>
        </div>
    );
};

export default ClosedClassicRegister;
