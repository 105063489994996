import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ClassicRegister from "./routes/ClassicRegister";
import ClosedClassicRegister from "./routes/ClosedClassicRegister";
import Unsubscribe from "./routes/Unsubscribe";
import UnsubscribeForm from "./routes/UnsubscribeForm";

const App = () => {
    return (
        <div className="App">
            <Router>
                <Switch>
                    {/*<Route path="/register" component={ClassicRegister} />*/}
                    <Route path="/register" component={ClosedClassicRegister} />
                    <Route path="/unsubscribe" component={Unsubscribe} />
                    <Route
                        path="/unsubscribe-form"
                        component={UnsubscribeForm}
                    />
                </Switch>
            </Router>
        </div>
    );
};

export default App;
